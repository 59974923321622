<script setup lang="ts">
import Field from "@/components/input/Field.vue"
import Label from "@/components/input/Label.vue"
import Form from "@/components/input/Form.vue"
import { useUserStore } from "@/stores/User"
import { ref, onMounted } from "vue"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import * as zod from "zod"

interface Props {
  type: string
}

const props = defineProps<Props>()
const emit = defineEmits(["close", "close:update"])

const schema = zod.object({
  code: zod.string()
    .min(6, { message: "Codes must be 6 characters." })
})

const disableSchema = zod.object({
  password: zod.string()
    .min(8, { message: "Passwords must be at least 8 characters." }),
  code: zod.string()
    .min(6, { message: "Codes must be 6 characters." })
})

const working = ref(false)
const initialLoadComplete = ref(false)
const QRCodeURL = ref("")
const TOTPSecret = ref("")
const codes = ref<any[]>([])
const enabled = ref(false)
const replaced = ref(false)

const error = ref(false)

onMounted(async () => {
  if (props.type === "setup") {
    await getTOTPSecret()
    initialLoadComplete.value = true
  } else {
    initialLoadComplete.value = true
  }
})

async function getTOTPSecret() {
  if (working.value) return
  working.value = true
  try {
    const response = await API().get("/totp/g")
    TOTPSecret.value = response.data.data.secret
    QRCodeURL.value = response.data.data.qrCodeURL
    initialLoadComplete.value = true
    working.value = false
  } catch (err) {
    console.error(err)
    working.value = false
  }
}

async function validateTOTPSecret(values: any) {
  if (working.value) return
  working.value = true
  try {
    const response = await API().post("/totp/e", {
      code: values.code
    })
    codes.value.push(...response.data.data.codes)
    enabled.value = true
    working.value = false
  } catch (err) {
    console.error(err)
    error.value = true
    working.value = false
  }
}

async function replaceTOTPCodes(values: any) {
  if (working.value) return
  working.value = true
  try {
    const response = await API().post("/totp/r", {
      password: values.password,
      code: values.code
    })
    codes.value = response.data.data.codes
    replaced.value = true
    working.value = false
  } catch (err) {
    console.error(err)
    error.value = true
    working.value = false
  }
}

async function disableTOTP(values: any) {
  if (working.value) return
  working.value = true
  try {
    await API().post("/totp/d", {
      password: values.password,
      code: values.code
    })
    working.value = false
    emit("close:update")
  } catch (err) {
    console.error(err)
    error.value = true
    working.value = false
  }
}
</script>

<template>
  <section>
    <div v-if="type === 'setup'">
      <div v-if="!initialLoadComplete">
        <p>Generating QR Code...</p>
      </div>
      <div v-else>
        <section class="space-y-3" v-if="!enabled">
          <p>Scan the QR code below with your authenticator app.</p>
          <img :src="QRCodeURL" alt="QR code" />
          <p>Code: {{ TOTPSecret }}</p>
          <p>Enter the code from your authenticator app below.</p>
          <Form @on-submit="validateTOTPSecret" :schema="schema" class="space-y-3">
            <div>
              <Label field="code">Code:</Label>
              <Field name="code" alt />
            </div>
            <div class="flex gap-4 justify-end">
              <button @click="emit('close')" class="inline-flex justify-center py-1 px-6 text-sm dark:hover:text-white dark:text-gray-400 text-gray-800" type="button">Cancel</button>
              <button class="bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 dark:hover:bg-gold-500 inline-flex justify-center py-1 px-6 border-0 text-sm font-bold rounded-md text-black" type="submit">Enable</button>
            </div>
          </Form>
        </section>
        <div v-if="enabled && codes.length > 0" class="space-y-3">
          <p>Two factor authentication is enabled for your account. Save the following recovery codes in a safe place, they cannot be shown to you again.</p>
          <ul class="grid grid-cols-2">
            <li v-for="code in codes" :key="code"><code>{{ code }}</code></li>
          </ul>
          <div class="flex gap-4 justify-end">
            <button @click="emit('close:update')" class="inline-flex justify-center py-1 px-6 text-sm dark:hover:text-white dark:text-gray-400 text-gray-800" type="button">Close</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="type === 'disable'" class="space-y-3">
      <p>Enter your password and the code from your authenticator app below to disable two factor authentication for your account.</p>
      <Form @on-submit="disableTOTP" :schema="disableSchema" class="space-y-3">
        <div>
          <Label field="password">Password:</Label>
          <Field type="password" name="password" alt />
        </div>
        <div>
          <Label field="code">Code:</Label>
          <Field name="code" help-text="A recovery code can be used here." alt />
        </div>
        <div class="flex gap-4 justify-end">
          <button @click="emit('close')" class="inline-flex justify-center py-1 px-6 text-sm dark:hover:text-white dark:text-gray-400 text-gray-800" type="button">Cancel</button>
          <button class="bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 dark:hover:bg-gold-500 inline-flex justify-center py-1 px-6 border-0 text-sm font-bold rounded-md text-black" type="submit">Disable</button>
        </div>
      </Form>
    </div>
    <div v-if="type === 'codes'" class="space-y-3">
      <div v-if="!replaced">
        <p>Enter your password and the code from your authenticator app below to generate new recovery codes. These new codes will replace your old codes.</p>
        <Form @on-submit="replaceTOTPCodes" :schema="disableSchema" class="space-y-3">
          <div>
            <Label field="password">Password:</Label>
            <Field type="password" name="password" alt />
          </div>
          <div>
            <Label field="code">Code:</Label>
            <Field name="code" help-text="Recovery codes will not work here." alt />
          </div>
          <div class="flex gap-4 justify-end">
            <button @click="emit('close')" class="inline-flex justify-center py-1 px-6 text-sm dark:hover:text-white dark:text-gray-400 text-gray-800" type="button">Cancel</button>
            <button class="bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 dark:hover:bg-gold-500 inline-flex justify-center py-1 px-6 border-0 text-sm font-bold rounded-md text-black" type="submit">Generate</button>
          </div>
        </Form>
      </div>
      <div v-if="replaced && codes.length > 0" class="space-y-3">
        <p>Save the following recovery codes in a safe place, they cannot be shown to you again.</p>
        <ul class="grid grid-cols-2">
          <li v-for="code in codes" :key="code"><code>{{ code }}</code></li>
        </ul>
        <div class="flex gap-4 justify-end">
          <button @click="emit('close:update')" class="inline-flex justify-center py-1 px-6 text-sm dark:hover:text-white dark:text-gray-400 text-gray-800" type="button">Close</button>
        </div>
      </div>
    </div>
  </section>
</template>
